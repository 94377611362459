import posthog from "posthog-js";

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthog.init(
      'phc_izVJ3lUlspwIUUk11G5WBaJ2kxESPJqSyPJGA4lo1oD',
      {
        api_host: 'https://eu.i.posthog.com',
        person_profiles: 'identified_only',
      }
    );
  },
};